<template>
  <app-collapse>
    <app-collapse-item :title="$t('pin_point.hide_pin')">
      <span>{{ $t("pin_point.hide_pin_description") }}</span>
      <b-form-group>
        <b-form-checkbox
          id="checkbox-3"
          name="checkbox-3"
          v-model="hide_pinpont"
        >
          {{ $t("pin_point.hide_pin") }}
        </b-form-checkbox>
      </b-form-group>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import { BFormGroup, BFormCheckbox, BForm } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  name: "pin-point-footer",
  components: {
    BFormGroup,
    BFormCheckbox,
    BForm,
    AppCollapse,
    AppCollapseItem,
  },
  props: ["value"],
  data() {
    return {
      hide_pinpont: this.value,
    };
  },
  watch: {
    hide_pinpont: function() {
      this.$emit("input", this.hide_pinpont);
    },
  },
};
</script>

<style></style>
