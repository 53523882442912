<template>
  <div>
    <b-modal
      id="modal-full"
      :ok-title="$t('Save')"
      :cancel-title="$t('Abort')"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      hideHeaderClose
      @ok.prevent="saveData"
      @cancel="resetModal"
      @show="showModal"
      size="lg"
      centered
    >
      <b-overlay :show="show" rounded="sm">
        <b-row>
          <b-col cols="12" class="modalTitle">
            <h2 class="font-weight-bolder">{{ $t(`media_label.${slug}`) }}</h2>
            <span>{{ $t("pin_point.preview_description") }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group :label="$t('pin_point.screen_label')">
              <b-input-group>
                <b-input-group-prepend>
                  <b-dropdown :text="dropdown_label" variant="outline-primary">
                    <b-dropdown-item @click="upload_video">
                      {{ $t("pin_point.Video") }}
                    </b-dropdown-item>
                    <b-dropdown-item @click="insert_youtube_link">
                      {{ $t("pin_point.Youtube") }}
                    </b-dropdown-item>
                    <b-dropdown-item @click="upload_image">
                      {{ $t("pin_point.Image") }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-input-group-prepend>
                <b-form-input
                  v-show="!(add_video || add_youtube_link || add_image_file)"
                  disabled
                />
                <b-form-file
                  accept=".jpg, .png"
                  v-show="add_image_file"
                  id="image_file"
                  v-model="image_file"
                  :placeholder="$t('pin_point.image_placeholder')"
                  @input="openEditorScreen(image_file, slug)"
                />
                <b-form-file
                  accept=".mp4"
                  v-show="add_video"
                  id="video_file"
                  v-model="video_file"
                  :placeholder="$t('pin_point.video_placeholder')"
                  @input="show_preview_link = null"
                />
                <b-form-input
                  :placeholder="$t('pin_point.youtube_placeholder')"
                  v-show="add_youtube_link"
                  id="youtube_link"
                  v-model="youtube_link"
                />
              </b-input-group>
            </b-form-group>
            <b-alert show v-show="add_video" variant="warning">
              {{ $t("pin_point.video_size_warning") }}
            </b-alert>
          </b-col>
          <b-row>
            <b-col>
              <b-img
                v-if="add_image_file"
                v-model="image"
                :src="preview_image"
                id="screen-preview-container"
                class="preview-img"
              />
            </b-col>
            <b-col md="12" v-if="add_youtube_link">
              <a :href="youtube_link" target="_blank">
                <feather-icon icon="FileIcon" /> {{ youtube_link }}
              </a>
            </b-col>
            <b-col md="12" v-if="show_preview_link">
              <a :href="file_url" target="_blank">
                <feather-icon icon="FileIcon" /> {{ file_name }}
              </a>
            </b-col>
          </b-row>
        </b-row>
        <b-row>
          <b-col cols="12" class="pin_hide">
            <footer-pin-point v-model="hide_media"></footer-pin-point>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BButton,
  VBModal,
  BModal,
  BFormFile,
  BDropdown,
  BDropdownItem,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormInput,
  BImg,
  BOverlay,
  BAlert,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FooterPinPoint from "../pin_point/footer";
import { updateMedia } from "@api/media";

export default {
  props: ["media", "slug"],
  name: "media_full",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    VBModal,
    BModal,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
    BImg,
    BOverlay,
    BAlert,
    FooterPinPoint,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      fields: null,
      image: null,
      type: null,
      image_file: null,
      video_file: null,
      dropdown_label: this.$t("pin_point.Action"),
      youtube_link: "",
      file_name: "",
      file_url: null,
      show_preview_link: null,
      add_video: false,
      add_youtube_link: false,
      add_image_file: false,
      preview_image: null,
      video_link: false,
      show: false,
      hide_media: !this.media.active,
    };
  },
  methods: {
    showModal() {
      this.fields = { ...this.media.fields };
      this.hide_media = !this.media.active;
      this.show_preview_link = false;
      this.preview_image = null;
      this.file_url = null;
      this.file_name = "";
      this.youtube_link = "";
      if (this.media.fields.image) {
        this.show_preview_link = false;
        this.upload_image();
        this.preview_image = this.media.fields.image.url;
      }
      if (this.media.fields.video) {
        this.upload_video();
        this.show_preview_link = true;
        this.file_url = this.media.fields.video.url;
        this.file_name = this.media.fields.video.name;
      }
      if (this.media.fields.link && this.media.fields != "") {
        this.insert_youtube_link();
        this.youtube_link = this.media.fields.link;
      }
    },
    saveData() {
      this.show = true;
      const $this = this;
      let formData = new FormData();
      if (this.add_video && $this.video_file) {
        //file video
        formData.append("video", $this.video_file);
      }
      if (this.add_image_file && $this.image_file) {
        //file immagine
        formData.append("image", $this.image_file);
      }
      if (this.add_youtube_link && $this.youtube_link != "") {
        //link youtube
        formData.append("link", $this.youtube_link);
      }
      formData.append("active", $this.hide_media ? 0 : 1);
      updateMedia($this.media.id, formData)
        .then((data) => {
          this.$emit("onSave");
          $this.$bvModal.hide("modal-full");
          this.show = false;
        })
        .catch((error) => {
          console.log(error);
          this.show = false;
        });
    },
    resetModal() {
      this.$bvModal.hide("modal-full");
      this.fields = { ...this.media.fields };
      this.hide_media = !this.media.active;
      this.preview_image = null;
    },
    openEditorScreen(file, type) {
      this.$emit("open_editor", file, type);
    },
    emptyField() {
      this.add_image_file = null;
    },
    upload_video() {
      if (this.video_file) {
        this.show_preview_link = false;
      } else {
        this.show_preview_link = true;
      }
      this.add_video = true;
      this.add_youtube_link = false;
      this.add_image_file = false;
      this.dropdown_label = this.$t("pin_point.Video");
    },
    insert_youtube_link() {
      this.show_preview_link = false;
      this.add_video = false;
      this.add_youtube_link = true;
      this.add_image_file = false;
      this.dropdown_label = this.$t("pin_point.Youtube");
    },
    upload_image() {
      this.show_preview_link = false;
      this.add_video = false;
      this.add_youtube_link = false;
      this.add_image_file = true;
      this.dropdown_label = this.$t("pin_point.Image");
    },
  },
};
</script>

<style></style>
