<template>
  <b-modal
    id="modal-social"
    :ok-title="$t('Save')"
    :cancel-title="$t('Abort')"
    cancel-variant="outline-secondary"
    hideHeaderClose
    no-close-on-backdrop
    @ok.prevent="saveData"
    @cancel="resetModal"
    @hidden="modalHidden"
    size="lg"
    centered
  >
    <b-overlay :show="show" rounded="sm">
      <b-row>
        <b-col cols="12" class="modalTitle">
          <h2 class="font-weight-bolder" v-if="!editTitle">
            {{ pinpoint.label }}
            <b-button
              v-if="pinpoint.type.editable"
              class="btn-icon rounded-circle"
              variant="flat-primary"
            >
              <feather-icon
                icon="EditIcon"
                @click="editTitle = true"
                variant="flat-primary"
              />
            </b-button>
          </h2>
          <b-form-input
            v-if="editTitle"
            type="text"
            v-model="pinpoint.label"
            maxlength="25"
            autofocus
          />
          <span>{{ $t("pin_point.social_info") }}</span>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label for="facebook">Facebook</label>
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="FacebookIcon" />
              </b-input-group-prepend>
              <b-form-input id="facebook" v-model="fields.facebook" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label for="instagram">Instagram</label>
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="InstagramIcon" />
              </b-input-group-prepend>
              <b-form-input id="instagram" v-model="fields.instagram" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label for="linkedin">Linkedin</label>
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="LinkedinIcon" />
              </b-input-group-prepend>
              <b-form-input id="linkedin" v-model="fields.linkedin" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label for="twitter">Twitter</label>
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="TwitterIcon" />
              </b-input-group-prepend>
              <b-form-input id="twitter" v-model="fields.twitter" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label for="youtube">Youtube</label>
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="YoutubeIcon" />
              </b-input-group-prepend>
              <b-form-input id="youtube" v-model="fields.youtube" />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="pin_hide">
          <icon-selector
            v-if="pinpoint.type.editable"
            v-model="icon"
          ></icon-selector>
          <footer-pin-point v-model="hide_pinpont"></footer-pin-point>
        </b-col>
      </b-row>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BModal,
  BInputGroup,
  BInputGroupPrepend,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FooterPinPoint from "./footer";
import IconSelector from "./icon-selector";

import { updatePinpoint } from "@api/pinpoint";

export default {
  props: ["pinpoint"],
  name: "pin-point-social",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BModal,
    BInputGroup,
    BInputGroupPrepend,
    BOverlay,
    FooterPinPoint,
    IconSelector,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: { ...this.pinpoint.fields },
      hide_pinpont: !this.pinpoint.active,
      show: false,
      editTitle: false,
      icon: this.pinpoint.icon,
    };
  },
  methods: {
    saveData() {
      const $this = this;
      this.show = true;
      let formData = new FormData();
      formData.append("label", $this.pinpoint.label);
      formData.append("facebook", $this.fields.facebook);
      formData.append("instagram", $this.fields.instagram);
      formData.append("linkedin", $this.fields.linkedin);
      formData.append("twitter", $this.fields.twitter);
      formData.append("youtube", $this.fields.youtube);
      formData.append("icon", $this.icon);
      formData.append("active", $this.hide_pinpont ? 0 : 1);
      updatePinpoint($this.pinpoint.id, formData)
        .then((data) => {
          this.$emit("onSave");
          this.$bvModal.hide("modal-social");
          this.show = false;
        })
        .catch((error) => {
          console.log(error);
          this.show = false;
        });
    },
    resetModal() {
      this.$bvModal.hide("modal-social");
      this.fields = { ...this.pinpoint.fields };
      this.hide_pinpont = !this.pinpoint.active;
      this.icon = this.pinpoint.icon;
    },
    modalHidden() {
      this.editTitle = false;
    },
  },
};
</script>

<style></style>
