<template>
  <b-modal
    id="modal-site_link"
    :ok-title="$t('Save')"
    :cancel-title="$t('Abort')"
    cancel-variant="outline-secondary"
    hideHeaderClose
    no-close-on-backdrop
    @ok.prevent="saveData"
    @cancel="resetModal"
    @hidden="modalHidden"
    size="lg"
    centered
  >
    <b-overlay :show="show" rounded="sm">
      <b-row>
        <b-col cols="12" class="modalTitle">
          <h2 class="font-weight-bolder" v-if="!editTitle">
            {{ pinpoint.label }}
            <b-button
              v-if="pinpoint.type.editable"
              class="btn-icon rounded-circle"
              variant="flat-primary"
            >
              <feather-icon
                icon="EditIcon"
                @click="editTitle = true"
                variant="flat-primary"
              />
            </b-button>
          </h2>
          <b-form-input
            v-if="editTitle"
            type="text"
            v-model="pinpoint.label"
            maxlength="25"
            autofocus
          />
          <span>{{ $t("pin_point.add_link_to_site") }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Link" label-for="link">
            <b-form-input id="link" placeholder="url" v-model="fields.url" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="pin_hide">
          <icon-selector
            v-if="pinpoint.type.editable"
            v-model="icon"
          ></icon-selector>
          <footer-pin-point v-model="hide_pinpont"></footer-pin-point>
        </b-col>
      </b-row>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BModal,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FooterPinPoint from "./footer";
import IconSelector from "./icon-selector";

import { updatePinpoint } from "@api/pinpoint";

export default {
  props: ["pinpoint"],
  name: "pin-point-site_link",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BForm,
    BButton,
    BModal,
    BOverlay,
    FooterPinPoint,
    IconSelector,
  },
  data() {
    return {
      fields: { ...this.pinpoint.fields },
      hide_pinpont: !this.pinpoint.active,
      show: false,
      editTitle: false,
      icon: this.pinpoint.icon,
    };
  },
  directives: {
    Ripple,
  },
  methods: {
    saveData() {
      const $this = this;
      this.show = true;
      let formData = new FormData();
      formData.append("label", $this.pinpoint.label);
      formData.append("url", $this.fields.url);
      formData.append("icon", $this.icon);
      formData.append("active", $this.hide_pinpont ? 0 : 1);
      updatePinpoint($this.pinpoint.id, formData)
        .then((data) => {
          this.$emit("onSave");
          this.$bvModal.hide("modal-site_link");
          this.show = false;
        })
        .catch((error) => {
          console.log(error);
          this.show = false;
        });
    },
    resetModal() {
      this.$bvModal.hide("modal-site_link");
      this.fields = { ...this.pinpoint.fields };
      this.icon = this.pinpoint.icon;
      this.hide_pinpont = !this.pinpoint.active;
    },
    modalHidden() {
      this.editTitle = false;
    },
  },
};
</script>

<style></style>
