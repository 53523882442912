<template>
  <b-modal
    id="modal-company_webinar"
    :ok-title="$t('Save')"
    :cancel-title="$t('Abort')"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    hideHeaderClose
    @ok.prevent="saveData"
    @cancel="resetModal"
    @show="showModal"
    @hidden="modalHidden"
    size="xl"
    centered
  >
    <b-overlay :show="show" rounded="sm">
      <b-row>
        <b-col cols="12" class="modalTitle">
          <h2 class="font-weight-bolder" v-if="!editTitle">
            {{ pinpoint.label }}
            <b-button
              v-if="pinpoint.type.editable"
              class="btn-icon rounded-circle"
              variant="flat-primary"
            >
              <feather-icon
                icon="EditIcon"
                @click="editTitle = true"
                variant="flat-primary"
              />
            </b-button>
          </h2>
          <b-form-input
            v-if="editTitle"
            type="text"
            v-model="pinpoint.label"
            maxlength="25"
            autofocus
          />
        </b-col>
      </b-row>
      <div>
        <b-form
          ref="form"
          :style="{ height: trHeight }"
          class="repeater-form"
          @submit.prevent="addItem"
        >
          <b-row
            v-for="(item, index) in webinar_list"
            :id="item.id"
            :key="item.id"
            ref="row"
          >
            <b-col md="3">
              <b-form-group
                :label="$t('pin_point.Title')"
                :label-for="`workshop_title_${item.id}`"
              >
                <b-form-input
                  :id="`workshop_title_${item.id}`"
                  type="text"
                  v-model="item.description"
                  :placeholder="$t('pin_point.workshop_title_placeholde')"
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group
                :label="$t('pin_point.Duration')"
                :label-for="`duration_${item.id}`"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="integer"
                  name="Number"
                >
                  <b-form-input
                    :id="`duration_${item.id}`"
                    v-model="item.duration"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('pin_point.insert_duration')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                :label="$t('pin_point.Link')"
                :label-for="`workshop_link_${item.id}`"
              >
                <b-form-input
                  :id="`workshop_link_${item.id}`"
                  type="text"
                  v-model="item.url"
                  :placeholder="$t('pin_point.Insert_link')"
                />
              </b-form-group>
            </b-col>

            <b-col lg="2" md="3" class="mb-50">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
              >
                <feather-icon icon="XIcon" class="mr-25" />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="addItem"
      >
        <feather-icon icon="PlusIcon" class="mr-25" />
        <span>{{ $t("pin_point.add") }}</span>
      </b-button>
      <b-col cols="12">
        <hr />
      </b-col>
      <b-row>
        <b-col cols="12" class="pin_hide">
          <icon-selector
            v-if="pinpoint.type.editable"
            v-model="icon"
          ></icon-selector>
          <footer-pin-point v-model="hide_pinpont"></footer-pin-point>
        </b-col>
      </b-row>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BModal,
  BOverlay,
} from "bootstrap-vue";

import { heightTransition } from "@core/mixins/ui/transition";
import flatPickr from "vue-flatpickr-component";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import Ripple from "vue-ripple-directive";
import FooterPinPoint from "./footer";
import IconSelector from "./icon-selector";

import { ValidationProvider, ValidationObserver } from "vee-validate";

import { getPinpoint, updatePinpoint } from "@api/pinpoint";

export default {
  props: ["pinpoint"],
  name: "pin-point-company_webinar",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BModal,
    BOverlay,
    FooterPinPoint,
    flatPickr,
    IconSelector,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      original_pin: null,
      webinar_list: [
        {
          id: 1,
          description: "",
          duration: 0,
          url: null,
        },
      ],
      nextItemId: 2,
      hide_pinpont: !this.pinpoint.active,
      show: false,
      editTitle: false,
      icon: this.pinpoint.icon,
    };
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    showModal() {
      const $this = this;
      getPinpoint($this.pinpoint.id)
        .then((data) => {
          $this.original_pin = data;
          $this.icon = data.icon;
          if ($this.original_pin.fields.webinar_list.length > 0) {
            $this.webinar_list = [];
            for (
              let i = 0;
              i < $this.original_pin.fields.webinar_list.length;
              i++
            ) {
              const element = $this.original_pin.fields.webinar_list[i];
              $this.nextItemId++;
              $this.webinar_list.push({
                id: i + 1,
                description: element.description,
                duration: element.duration,
                url: element.url,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveData() {
      const $this = this;
      this.show = true;
      let formData = new FormData();
      formData.append("label", $this.pinpoint.label);
      if ($this.webinar_list.length == 0) {
        formData.append(`webinar_list`, []);
      } else {
        for (let i = 0; i < $this.webinar_list.length; i++) {
          formData.append(
            `webinar_list[${i}][description]`,
            $this.webinar_list[i].description
          );
          formData.append(
            `webinar_list[${i}][duration]`,
            $this.webinar_list[i].duration
          );
          formData.append(`webinar_list[${i}][url]`, $this.webinar_list[i].url);
        }
      }
      formData.append("icon", $this.icon);
      formData.append("active", $this.hide_pinpont ? 0 : 1);
      updatePinpoint($this.pinpoint.id, formData)
        .then((data) => {
          this.$emit("onSave");
          this.$bvModal.hide("modal-company_webinar");
          this.show = false;
        })
        .catch((error) => {
          console.log(error);
          this.show = false;
        });
    },
    resetModal() {
      this.$bvModal.hide("modal-company_webinar");
      this.fields = { ...this.pinpoint.fields };
      this.icon = this.original_pin.icon;
      this.pinpoint.label = this.original_pin.label;
      this.hide_pinpont = !this.pinpoint.active;
    },
    addItem() {
      this.webinar_list.push({
        id: this.nextItemId++,
        description: "",
        duration: 0,
        url: "",
      });
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.webinar_list.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        //per evitare che lo esegua se non esiste
        if (typeof this.$refs.form !== "undefined") {
          this.trSetHeight(this.$refs.form.scrollHeight);
        }
      });
    },
    modalHidden() {
      this.editTitle = false;
    },
  },
};
</script>

<style>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
