<template>
  <div>
    <b-modal
      id="modal-image"
      :ok-title="$t('Save')"
      :cancel-title="$t('Abort')"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      hideHeaderClose
      @ok.prevent="saveData"
      @cancel="resetModal"
      @show="showModal"
      size="lg"
      centered
    >
      <b-overlay :show="show" rounded="sm">
        <b-row>
          <b-col cols="12" class="modalTitle">
            <h2 class="font-weight-bolder">{{ $t(`media_label.${slug}`) }}</h2>
            <span>{{ $t("pin_point.frontal_totem_description") }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6" class="media-input-column">
            <b-form-group :label="$t('pin_point.frontal_totem_label')">
              <b-form-file
                accept=".jpg, .png"
                id="media_file"
                v-model="media_file"
                @input="openEditorPreviewMediaImage(media_file, slug)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6" class="column-preview-container">
            <b-img
              :src="preview_media"
              id="totem-preview-container"
              :class="bg_class ? 'preview-img-bg preview-img' : 'preview-img'"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="pin_hide">
            <footer-pin-point v-model="hide_media"></footer-pin-point>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BButton,
  VBModal,
  BModal,
  BFormFile,
  BImg,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FooterPinPoint from "../pin_point/footer";
import { updateMedia } from "@api/media";

export default {
  props: ["media", "slug"],
  name: "media_image",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BModal,
    BFormFile,
    BImg,
    BOverlay,
    FooterPinPoint,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      media_file: null,
      preview_media: "",
      fields: null,
      hide_media: !this.media.active,
      show: false,
      bg_class: false,
    };
  },
  methods: {
    showModal() {
      this.fields = { ...this.media.fields };
      this.hide_media = !this.media.active;
      if (this.slug == "logo_wall_e" || this.slug == "logo_wall_xl") {
        this.bg_class = true;
      }
      this.preview_media = null;
      if (this.media.fields.image) {
        this.preview_media = this.media.fields.image.url;
      }
    },
    saveData() {
      const $this = this;
      this.show = true;
      let formData = new FormData();
      if ($this.media_file) {
        formData.append("image", $this.media_file);
      }
      formData.append("active", $this.hide_media ? 0 : 1);
      updateMedia($this.media.id, formData)
        .then((data) => {
          this.$emit("onSave");
          $this.$bvModal.hide("modal-image");
          this.show = false;
        })
        .catch((error) => {
          console.log(error);
          this.show = false;
        });
    },
    resetModal() {
      this.$bvModal.hide("modal-image");
      this.fields = { ...this.media.fields };
      this.hide_media = !this.media.active;
      this.preview_media = null;
    },
    openEditorPreviewMediaImage(file, type) {
      this.$emit("open_editor", file, type);
    },
    emptyField() {
      this.media_file = null;
    },
  },
};
</script>

<style>
.preview-img-bg {
  background-color: black;
}
</style>
