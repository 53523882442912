<template>
  <b-modal
    id="modal-promo"
    :ok-title="$t('Save')"
    :cancel-title="$t('Abort')"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    hideHeaderClose
    @ok.prevent="saveData"
    @cancel="resetModal"
    @shown="showModal"
    size="lg"
    centered
  >
    <b-overlay :show="show" rounded="sm">
      <b-row>
        <b-col cols="12" class="modalTitle">
          <h2 class="font-weight-bolder" v-if="!editTitle">
            {{ pinpoint.label }}
            <b-button
              v-if="pinpoint.type.editable"
              class="btn-icon rounded-circle"
              variant="flat-primary"
            >
              <feather-icon
                icon="EditIcon"
                @click="editTitle = true"
                variant="flat-primary"
              />
            </b-button>
          </h2>
          <b-form-input
            v-if="editTitle"
            type="text"
            v-model="pinpoint.label"
            maxlength="25"
            autofocus
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <Vueditor id="editor_promo"></Vueditor>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" style="margin-top: 10px">
          <b-form-checkbox id="checkbox-4" name="checkbox-4" v-model="contacts">
            {{ $t("pin_point.contacts_tab") }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="pin_hide">
          <icon-selector
            v-if="pinpoint.type.editable"
            v-model="icon"
          ></icon-selector>
          <footer-pin-point v-model="hide_pinpont"></footer-pin-point>
        </b-col>
      </b-row>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BForm,
  BButton,
  BModal,
  BFormFile,
  BOverlay,
  BFormInput,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FooterPinPoint from "./footer";
import IconSelector from "./icon-selector";

import { getPinpoint, updatePinpoint } from "@api/pinpoint";
import { createEditor } from "vueditor";

export default {
  props: ["pinpoint"],
  name: "pin-point-promo",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BForm,
    BButton,
    BModal,
    BFormFile,
    BOverlay,
    FooterPinPoint,
    IconSelector,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      contacts: !!this.pinpoint.fields.contacts,
      original_pin: null,
      editor: null,
      hide_pinpont: !this.pinpoint.active,
      show: false,
      editTitle: false,
      icon: this.pinpoint.icon,
    };
  },
  methods: {
    showModal() {
      this.editor = createEditor("#editor_promo");
      this.editor.upload = function(obj, callback) {
        var reader = new FileReader();
        reader.readAsDataURL(obj.files[0]);
        reader.onloadend = function() {
          callback(reader.result);
        };
      };
      const $this = this;
      getPinpoint($this.pinpoint.id)
        .then((data) => {
          $this.original_pin = data;
          $this.icon = data.icon;
          if (data.fields.text != null) {
            $this.editor.setContent(data.fields_html_content[data.fields.text]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveData() {
      const $this = this;
      let formData = new FormData();
      this.show = true;
      formData.append("label", $this.pinpoint.label);
      formData.append("text", $this.editor.getContent());
      formData.append("contacts", $this.contacts ? 1 : 0);
      formData.append("active", $this.hide_pinpont ? 0 : 1);
      formData.append("active", $this.hide_pinpont ? 0 : 1);
      formData.append("icon", $this.icon);
      updatePinpoint($this.pinpoint.id, formData)
        .then((data) => {
          this.$emit("onSave");
          this.$bvModal.hide("modal-promo");
          this.show = false;
        })
        .catch((error) => {
          console.log(error);
          this.show = false;
        });
    },
    resetModal() {
      this.$bvModal.hide("modal-promo");
      this.icon = this.original_pin.icon;
      this.pinpoint.label = this.original_pin.label;
      this.editor.setContent(this.original_pin.fields.text);
      this.contacts = !!this.pinpoint.fields.contacts;
      this.hide_pinpont = !this.pinpoint.active;
    },
    modalHidden() {
      this.editTitle = false;
    },
  },
};
</script>

<style></style>
