import { api } from "./index";

export const getPinpointsByStand = (id) => {
  return new Promise((resolve, reject) => {
    api
      .get(`stands/${id}/pinpoints`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const getPinpoint = (id) => {
  return new Promise((resolve, reject) => {
    api
      .get(`pinpoints/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const updatePinpoint = (id, formData) => {
  formData.append("_method", "PUT");
  return new Promise((resolve, reject) => {
    api
      .post(`pinpoints/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const uploadPinpointFile = (id, formData) => {
  return new Promise((resolve, reject) => {
    api
      .post(`pinpoints/${id}/upload_file`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};
