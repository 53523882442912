<template>
  <b-modal
    id="modal-gallery"
    :ok-title="$t('Save')"
    :cancel-title="$t('Abort')"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    hideHeaderClose
    @ok.prevent="saveData"
    @cancel="resetModal"
    @show="showModal"
    @hidden="modalHidden"
    size="xl"
    centered
  >
    <b-overlay :show="show" rounded="sm">
      <b-row>
        <b-col cols="12" class="modalTitle">
          <h2 class="font-weight-bolder" v-if="!editTitle">
            {{ pinpoint.label }}
            <b-button
              v-if="pinpoint.type.editable"
              class="btn-icon rounded-circle"
              variant="flat-primary"
            >
              <feather-icon
                icon="EditIcon"
                @click="editTitle = true"
                variant="flat-primary"
              />
            </b-button>
          </h2>
          <b-form-input
            v-if="editTitle"
            type="text"
            v-model="pinpoint.label"
            maxlength="25"
            autofocus
          />
        </b-col>
      </b-row>
      <div>
        <b-form
          ref="form"
          :style="{ height: trHeight }"
          class="repeater-form"
          @submit.prevent="addItem"
        >
          <b-row
            v-for="(item, index) in gallery_list"
            :id="item.id"
            :key="item.id"
            ref="row"
          >
            <b-col md="2">
              <b-form-group
                :label="$t('pin_point.image_url')"
                :label-for="`documents_file_${item.id}`"
              >
                <b-form-file
                  :id="`documents_file_${item.id}`"
                  v-model="item.file"
                  :placeholder="$t('any_file_select')"
                  accept=".jpg, .png"
                  @input="openEditorPreview(item.file, 'gallery', item.id)"
                />
                <b-img
                  :id="`documents_image_${item.id}`"
                  :src="item.image"
                  class="preview-img"
                >
                </b-img>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('pin_point.image_description')"
                :label-for="`documents_description_${item.id}`"
              >
                <b-form-input
                  :id="`documents_description_${item.id}`"
                  type="text"
                  v-model="item.description"
                  :placeholder="$t('pin_point.image_description')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                :label="$t('pin_point.image_link')"
                :label-for="`documents_url_${item.id}`"
              >
                <b-form-input
                  :id="`documents_url_${item.id}`"
                  type="text"
                  v-model="item.url"
                  :placeholder="$t('pin_point.image_link')"
                />
              </b-form-group>
            </b-col>
            <b-col lg="1" md="1" class="mb-50">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
              >
                <feather-icon icon="XIcon" class="mr-25" />
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="addItem"
      >
        <feather-icon icon="PlusIcon" class="mr-25" />
        <span>{{ $t("pin_point.add") }}</span>
      </b-button>
      <b-col cols="12">
        <hr />
      </b-col>
      <b-row>
        <b-col cols="12" class="pin_hide">
          <icon-selector v-if="pinpoint.type.editable" v-model="icon">
          </icon-selector>
          <footer-pin-point v-model="hide_pinpont"></footer-pin-point>
        </b-col>
      </b-row>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BImg,
  BButton,
  BModal,
  BOverlay,
  BFormFile,
} from "bootstrap-vue";

import { heightTransition } from "@core/mixins/ui/transition";
import flatPickr from "vue-flatpickr-component";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import Ripple from "vue-ripple-directive";
import FooterPinPoint from "./footer";
import IconSelector from "./icon-selector";

import { ValidationProvider, ValidationObserver } from "vee-validate";

import { getPinpoint, updatePinpoint, uploadPinpointFile } from "@api/pinpoint";

export default {
  props: ["pinpoint"],
  name: "pin-point-gallery",
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BImg,
    BButton,
    BModal,
    BOverlay,
    FooterPinPoint,
    flatPickr,
    IconSelector,
    ValidationProvider,
    ValidationObserver,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      original_pin: null,
      gallery_list: [
        {
          id: 1,
          image: "",
          url: "",
          description: "",
          file: null,
        },
      ],
      nextItemId: 0,
      hide_pinpont: !this.pinpoint.active,
      show: false,
      editTitle: false,
      icon: this.pinpoint.icon,
      currentId: -1,
    };
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    showModal() {
      const $this = this;
      getPinpoint($this.pinpoint.id)
        .then((data) => {
          $this.original_pin = data;
          $this.icon = data.icon;
          if ($this.original_pin.fields.gallery_list.length > 0) {
            $this.gallery_list = [];
            for (
              let i = 0;
              i < $this.original_pin.fields.gallery_list.length;
              i++
            ) {
              const element = $this.original_pin.fields.gallery_list[i];
              $this.nextItemId++;
              $this.gallery_list.push({
                id: i,
                image: element.image,
                url: element.url,
                description: element.description,
                file: null,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveData() {
      const $this = this;
      this.show = true;
      let formData = new FormData();
      formData.append("label", $this.pinpoint.label);
      if ($this.gallery_list.length == 0) {
        formData.append(`gallery_list`, []);
      } else {
        for (let i = 0; i < $this.gallery_list.length; i++) {
          formData.append(
            `gallery_list[${i}][image]`,
            $this.gallery_list[i].image
          );
          formData.append(`gallery_list[${i}][url]`, $this.gallery_list[i].url);
          formData.append(
            `gallery_list[${i}][description]`,
            $this.gallery_list[i].description
          );
        }
      }
      formData.append("icon", $this.icon);
      formData.append("active", $this.hide_pinpont ? 0 : 1);
      updatePinpoint($this.pinpoint.id, formData)
        .then((data) => {
          this.$emit("onSave");
          this.$bvModal.hide("modal-gallery");
          this.show = false;
        })
        .catch((error) => {
          console.log(error);
          this.show = false;
        });
    },
    resetModal() {
      this.$bvModal.hide("modal-gallery");
      this.fields = { ...this.pinpoint.fields };
      this.icon = this.original_pin.icon;
      this.pinpoint.label = this.original_pin.label;
      this.hide_pinpont = !this.pinpoint.active;
    },
    addItem() {
      this.gallery_list.push({
        id: this.nextItemId++,
        image: "",
        url: "",
        description: "",
        file: null,
      });
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    openEditorPreview(file, type, id) {
      this.currentId = id;
      this.$emit("open_editor", file, type);
    },
    showImg(image, imageUrl) {
      const $this = this;
      $this.show = true;
      let formData = new FormData();
      formData.append("file", image);
      uploadPinpointFile($this.pinpoint.id, formData)
        .then((data) => {
          $this.gallery_list.forEach((element) => {
            if (element.id == $this.currentId) {
              element.image = data;
              element.file = image;
              $this.currentId = -1;
              $this.show = false;
              return;
            }
          });
        })
        .catch((error) => {
          console.log(error);
          $this.show = false;
        });
    },
    emptyField() {
      const $this = this;
      this.gallery_list.forEach((element) => {
        if (element.id == $this.currentId) {
          element.file = null;
          this.currentId = -1;
          return;
        }
      });
    },
    removeItem(index) {
      this.gallery_list.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        //per evitare che lo esegua se non esiste
        if (typeof this.$refs.form !== "undefined") {
          this.trSetHeight(this.$refs.form.scrollHeight);
        }
      });
    },
    modalHidden() {
      this.editTitle = false;
    },
  },
};
</script>

<style>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
